<template>
	<div class="container">
		<bread-crums :nesting="['Dental Care', 'Dentures']"></bread-crums>
		<intro button-text="Contact Us" button-link="/contact" title="Dentures" :content="paragraphs"/>
		<div class="pgcontent">
			<div v-for="card in cards" :key="card.title" class="card">
				<h2>{{card.title}}</h2>
				<p>{{card.content}}</p>
			</div>
      <div v-for="section in sections" :key="section.title">
        <p>{{ section }}</p>
      </div>
			<div class="table">
				<price-table :procedure="Procedures['categories'][1]"></price-table>
			</div>
		</div>      
	</div>
</template>

<script>
import Intro from '../components/Intro.vue'
import PriceTable from '../components/PriceTable.vue'
import  Procedures  from "../assets/json/procedures.json";
import BreadCrums from '../components/BreadCrums.vue';
export default {
	name: 'Dentures',
	components: {
		Intro,
		PriceTable,
		BreadCrums
	},
	data(){
		return{
			Procedures,
			paragraphs: [
					"Removable appliances that can comfortably replace missing teeth.",
          "If you are missing some or all of your teeth, nowadays we a variety of options available. The denture is a removable appliance that is constructed to replace missing teeth – they may be retained by implants and could be composed of acrylic, nylon or metal. They are designed to fit closely against your gum tissue and prevent further long-term issues such a difficulty eating and speaking. Adjacent remaining teeth any drift over time if teeth are not replaced into the gaps. Unlike fixed work such as crowns and bridges they will need to be removed daily for cleaning. As always we will guide you through the stages of treatment and ensure that the best option are available to you."
			],
			cards:[
			],
      sections:[
        "There are several stages in constructing new dentures. We will start by taking some impressions (moulds) of your teeth or supporting ridges to assess how the denture will be made. After this, your dentist will recreate your bite and suggest where the denture teeth will go and how they will be designed to be supported by the new denture. The final stages involve a trial denture stage and then a final fitting. The materials and design of the denture are individual to each patient situation and your dentist will guide you through this. On average the process takes about 6-8 weeks to construct the new denture, depending on your availability for appointments and the lab working times. If you need the denture sooner, we can do our best to accommodate this by co-ordinating with the laboratory."
      ]
		}
	}
}
</script>

<style scoped>
	.pgcontent {
		background-color: #FFF;
		padding: 1.5rem;
	}
	h2{
		width: 95%;
		color: var(--primary)
	}
	@media screen and (min-width: 375px){
		.pgcontent{
			padding: 1.5rem 2.5rem;
		}
	}
	@media screen and (min-width: 768px){
		.pgcontent{
			padding: 1.5rem6rem;
		}	
	}
	@media screen and (min-width: 1024px) {
		
		.container{
			padding-top: 1rem;
		}
		.pgcontent{
			display: flex;
			flex-wrap: wrap;
			padding: 2rem var(--padding1);
			justify-content: center;
		}
		.card{
			max-width: 50%;			
		}
		p{
			width: 70%;
		}
		.table{
			width: 100%;
		}
		
	}
	@media screen and (min-width: 1440px) {		
		.pgcontent{
			padding: 2rem var(--padding2);
		}    
	}
	@media screen and (min-width: 1750px){
		.pgcontent{
			padding: 2rem 4rem;
		}    
	}
</style>